// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/codebuild/output/src822642936/src/homeandblissreno/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("/codebuild/output/src822642936/src/homeandblissreno/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("/codebuild/output/src822642936/src/homeandblissreno/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ourstory-js": () => import("/codebuild/output/src822642936/src/homeandblissreno/src/pages/ourstory.js" /* webpackChunkName: "component---src-pages-ourstory-js" */),
  "component---src-pages-renovations-js": () => import("/codebuild/output/src822642936/src/homeandblissreno/src/pages/renovations.js" /* webpackChunkName: "component---src-pages-renovations-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/codebuild/output/src822642936/src/homeandblissreno/.cache/data.json")

